import React, { useCallback } from 'react';
import { withTranslation } from "react-i18next";
import Select from "$shared/components/Select";
import FormGroup from "$shared/components/FormGroup";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";

const options = [
    { id: 'all', label: 'search.labels.all', value: '', checked: true },
    { id: 'new', label: 'search.props_age.new', value: 'new' },
    { id: 'old', label: 'search.props_age.old', value: 'old' }
];

const PropsAge = ({ name, className, t, onChange, onBlur, data, value }) => {

    const handleChange = useCallback(({ value }) => {
        onChange(name, value);
    }, [onChange]);

    return (
        <FormGroup
            label={t('search.props_age.title')}
            classname={className}
            hint={t('search.props_age.hint')}
        >
            <FormikFastField
                component={Select}
                name={name}
                mode="radioSelect"
                onChange={handleChange}
                onBlur={onBlur}
                placeholder={t('search.props_age.placeholder')}
                data={data.map(option => ({
                    ...option,
                    label: t(option.label),
                    checked: value ? value === option.id : option.checked
                }))}
            />
        </FormGroup>
    );
}

export default Object.assign(withTranslation()(PropsAge), {
    options
});
