import React from 'react';
import { withTranslation } from "react-i18next";
import Header from "../../components/layout/Header";
import Index from "../../shared/components/Layout";
import Content from "../../components/layout/Content";
import PhotoImage from "../../img/about.jpg";
// import Questions from "../../components/layout/Questions";
import FormFeedback from "../../components/layout/FormFeedback";
import Footer from "../../components/layout/Footer";

/*const questionsForAboutPage = [
    {
        id: 1,
        title: 'about.questions.title1',
        text: 'about.questions.text1'
    },
    {
        id: 2,
        title: 'about.questions.title2',
        text: 'about.questions.text2'
    },
    {
        id: 3,
        title: 'about.questions.title3',
        text: 'about.questions.text3'
    },
];*/

const About = ({ t }) => (
    <>
        <Header />
        <Index className="partners">

            <Content
                img={PhotoImage}
                title={t('about.headline')}
            >
                <p>
                    {t('about.text1')}
                </p>
                <p>
                    {t('about.text2')}
                </p>
                <p>
                    {t('about.text3')}
                </p>
            </Content>
            {/*<Questions title="Title (think what to right here)" questions={questionsForAboutPage} />*/}
            <FormFeedback />
        </Index>
        <Footer />
    </>
);

export default withTranslation()(About);
