import React from 'react';
import { withTranslation } from "react-i18next";

const NoData = ({ t }) => (
    <div className="locations-page__no-data">
        {t('search.no_data')}
    </div>
);

export default withTranslation()(NoData);
