import React from 'react';

const imgPlaces = {
    LEFT: 'left',
    RIGHT: 'right'
};

const Content = ({
    img,
    title,
    children,
    imgPlace = imgPlaces.LEFT,
    containerClass = '',
    contentClass = '',
    textClass = '',
    imgClass = ''
}) => (
    <div className={`content-wrapper ${containerClass} ${img ? 'with-image' : ''}`}>
        {img && imgPlace === imgPlaces.LEFT && (
            <img
                className={`content-wrapper__img left ${imgClass}`}
                src={img}
                alt={img}
            />
        )}
        <div className={`content-wrapper__content ${contentClass}`}>
            {title && <h1 className="content-wrapper__title">{title}</h1>}
            <div className={`content-wrapper__text ${textClass}`}>{children}</div>
        </div>
        {img && imgPlace === imgPlaces.RIGHT && (
            <img
                className={`content-wrapper__img right ${imgClass}`}
                src={img}
                alt={img}
            />
        )}
    </div>
);

export {
    imgPlaces
};
export default Content;
