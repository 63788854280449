import React, { useCallback } from 'react';
import { ReactComponent as CloseIcon } from '$icons/close.svg';
import './styles.css';

const MobileMenu = ({ children, onClose }) => {

    const onClickOnLayout = useCallback((event) => {
        if (event.target.closest('.mobile-menu-content')) {
            return;
        }

        onClose();
    }, [onClose]);

    return (
        <div
            className="mobile-menu-layout"
            onClick={onClickOnLayout}
            role="presentation"
        >
            <div className="mobile-menu-content">
                <CloseIcon className="mobile-menu-content__close" onClick={onClose} />
                {children}
            </div>
        </div>
    );
};

export default MobileMenu;
