const normalizeURL = (values) => {
    const { locations, ...restValues } = values;
    const searchParams = {};
    Object.keys(restValues).forEach((key) => {
        if (restValues[key] instanceof Object) {
            const { min = '', max = '' } = restValues[key];
            if (min !== '') {
                const nameMinField = `${key}Min`;
                searchParams[nameMinField] = min;
            }

            if (max !== '') {
                const nameMinField = `${key}Max`;
                searchParams[nameMinField] = max;
            }
        } else {
            if (values[key]) {
                searchParams[key] = values[key];
            }
        }
    });
    return searchParams;
};

export default normalizeURL;

