import React from 'react';

export const typesField = {
    INTEGER: 'int',
    FLOAT: 'float'
};

const normalize = (type, value) => {
    if (type === typesField.FLOAT) {
        const [normalizeValue] = (value || '').match(/-?\d+[,.]?\d?\d?/) || [];
        if (normalizeValue == null) {
            return null;
        }
        return normalizeValue.replace(',', '.');
    }

    if (type === typesField.INTEGER) {
        const [normalizeValue] = (value || '').match(/-?\d+/) || [];
        if (normalizeValue == null) {
            return null;
        }
        return Number(normalizeValue);
    }

    return value;
};

const NumberWrapper = component => ({ form, typeField, ...props }) => {
    const onChange = (event) => {
        const { name, value } = event.target;
        if (!value) {
            form.setFieldValue(name, '');
            return;
        }

        form.setFieldTouched(event.target.name);

        if (value.indexOf('-') === 0 && value.length === 1) {
            form.setFieldValue(name, value);
            return;
        }

        const normalizeValue = normalize(typeField, value);
        if (normalizeValue !== null) {
            form.setFieldValue(name, normalizeValue);
        }
    };

    const Component = component;
    return (
        <Component {...props} onChange={onChange} />
    );
};

export default NumberWrapper;
