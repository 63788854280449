import React from 'react';
import CookieConsent from "react-cookie-consent";
import { withTranslation } from "react-i18next";

const Cookies = ({ t }) => (
    <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="Cookies"
        style={{ color: "#000", background: "#fff" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
    >
        {t('cookies')}
    </CookieConsent>
);

export default withTranslation()(Cookies);
