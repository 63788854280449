import React from 'react';
import { withTranslation } from "react-i18next";
import Select from "$shared/components/Select";
import FormGroup from "$shared/components/FormGroup";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";

const AreaSize = ({ name, className, t, onChange, onBlur, data }) => (
    <FormGroup
        label={t('search.area_size.title')}
        classname={className}
        hint={t('search.area_size.hint')}
    >
        <FormikFastField
            component={Select}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            mode="radioSelect"
            data={data}
            className="select-size"
            placeholder={t('search.area_size.placeholder')}
        />
    </FormGroup>
);

const dependencyFiledName = 'population';
const options = [
    {
        key: 'all',
        id: 'all-area-size',
        label: 'search.labels.all',
        value: '',
        dependencyValue: { min: '', max: '' }
    },
    {
        id: 'small_location',
        label: 'search.area_size.small_location',
        value: 'small_location',
        dependencyValue: { min: '', max: 200000 }
    },
    {
        id: 'medium_location',
        label: 'search.area_size.medium_location',
        value: 'medium_location',
        dependencyValue: { min: 200000, max: 500000 }
    },
    {
        id: 'big_location',
        label: 'search.area_size.big_location',
        value: 'big_location',
        dependencyValue: { min: 500000, max: '' }
    },
    {
        id: 'custom',
        label: 'search.labels.custom',
        value: ''
    }
];

export default Object.assign(withTranslation()(AreaSize), {
    options,
    dependencyFiledName
});
