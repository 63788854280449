import React from 'react';
import { withTranslation } from "react-i18next";
import Header from "../../components/layout/Header";
import Index from "../../shared/components/Layout";
import Content from "../../components/layout/Content";
import PhotoImage from "../../img/mark.jpg";
import Footer from "../../components/layout/Footer";


const Team = ({ t }) => (
    <>
        <Header />
        <Index className="partners">
            <Content
                containerClass="first-content"
                title={t('team.hero.headline')}
            >
                <p>{t('team.hero.paragraph1')}</p>
                <p>{t('team.hero.paragraph2')}</p>
            </Content>
            <Content
                img={PhotoImage}
            >
                <h1>{t('team.cofounder1.headline')}</h1>
                <h2>{t('team.cofounder1.role')}</h2>
                <p></p>
                <p>{t('team.cofounder1.paragraph1')}</p>
                <p>{t('team.cofounder1.paragraph2')}</p>
                <p>{t('team.cofounder1.paragraph3')}</p>
            </Content>
        </Index>
        <Footer />
    </>
);

export default withTranslation()(Team);
