import React from 'react';
import { withTranslation } from "react-i18next";
import classnames from 'classnames';
import DropdownLocation from "../components/DropdownLocation";
import AdvancedFilters from "../components/AdvancedFilters";
import AdvancedFilterShow from "../components/AdvancedFilters/AdvancedFilterShow";
import WarningUsageUnsupportedFilters from "../components/WarningUsageUnsupportedFilters";

const Filters = ({
    t,
    values,
    setFieldValue,
    handleSubmit,
    children,
    onShowAdvanced,
    onCloseOnMobile,
    isVisible
}) => {
    const advancedFiltersClassname = classnames(
        'locations-page__content__advanced-filters',
        isVisible && 'is-show'
    );

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <DropdownLocation
                name="locations"
                setFieldValue={setFieldValue}
                listId={values?.listId}
            />
            <WarningUsageUnsupportedFilters />
            <div className="locations-page__content">
                <div className="filters-column">
                    <AdvancedFilterShow t={t} onShowAdvanced={onShowAdvanced} isVisible={false} />
                    <div className={advancedFiltersClassname}>
                        <AdvancedFilters
                            onShowAdvanced={onShowAdvanced}
                            onCloseOnMobile={onCloseOnMobile}
                            setFieldValue={setFieldValue}
                            values={values}
                            t={t}
                            isVisible={isVisible}
                            isLocationPage
                        />
                    </div>
                    <button
                        className="btn filters__submit is-mobile"
                    >
                        {t('search.button')}
                    </button>
                </div>
                {children}
            </div>
        </form>
    )
};

export default withTranslation()(Filters);
