import React from 'react';
import { withTranslation } from "react-i18next";
import Select from "$shared/components/Select";
import FormGroup from "$shared/components/FormGroup";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";

const dependencyFiledName = 'vacancyRate';
const options = [
    {
        id: 'all-vacancy-rate',
        key: 'all',
        label: 'search.labels.all',
        value: '',
        dependencyValue: { min: '', max: '' }
    },
    {
        id: 'small',
        label: '< 2%',
        value: 'small',
        dependencyValue: { min: '', max: 2 }
    },
    {
        id: 'medium',
        label: '< 5%',
        value: 'medium',
        dependencyValue: { min: '', max: 5 }
    },
    {
        id: 'custom',
        label: 'search.labels.custom',
        value: '',
    }
];

const VacancyRate = ({ name, className, t, onChange, onBlur, data }) => (
    <FormGroup
        label={t('search.vacancy.title')}
        classname={className}
        hint={t('search.vacancy.hint')}
    >
        <FormikFastField
            component={Select}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            mode="radioSelect"
            data={data}
            placeholder={t('search.vacancy.placeholder')}
        />
    </FormGroup>
);

export default Object.assign(withTranslation()(VacancyRate), {
    dependencyFiledName,
    options
});
