import React from 'react';
import styled from 'styled-components';
import backgroundUrl from './background.png';
import { withTranslation } from "react-i18next";
import EmailTouch from "$shared/components/EmailTouch";
import Title from "$shared/components/Title";
import ServiceCard, { services } from "$components/Services";
import Header from "$components/layout/Header";
import SmallCard from "$components/LocationCard/SmallCard";
import Footer from "$components/layout/Footer";

import SeasideImage from '$img/seaside.jpg';
import StudentImage from '$img/student.jpeg';
import UpImage from '$img/up.jpg';
import EastImage from '$img/east.jpg';
import Filters from "$components/Filters";


import './styles.css';
import recoveryInitialValues from "$components/Filters/recoveryInitialValues";

const areas = [
    {
        name: 'home.collections.cards.title1',
        imgUrl: SeasideImage,
        yieldParam: '3',
        population: '-5',
        url: '/locations?listId=77C7D2EAFB712959D188C45B944CE0D9',
    },
    {
        name: 'home.collections.cards.title2',
        imgUrl: StudentImage,
        yieldParam: '3.5',
        population: '0',
        url: '/locations?numberStudentsMin=23500',
    },
    {
        name: 'home.collections.cards.title3',
        imgUrl: UpImage,
        yieldParam: '3.5',
        population: '+4',
        url: '/locations?populationDevMin=3&populationDevSelect=rapidly',
    },
    {
        name: 'home.collections.cards.title4',
        imgUrl: EastImage,
        yieldParam: '3.5',
        population: '0',
        url: '/locations?listId=ED6EA416259BA9246D4AC7EACEE34241',
    }
];

const Main = styled.div`
    display: block;
    min-height: 100vh;
    width: 100%;
    text-align: center;
    background: url(${backgroundUrl}) no-repeat fixed;
    background-position-x: center;
    background-position-y: 0;
    background-size: cover;
    padding-top: calc(12vh);
    padding-bottom: 32px;
`;

const Intro = styled.div`
    max-width: 700px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.2px;
    color: white;
    margin: auto;
    padding: 5%;
    
    @media (max-width: 900px) {
      font-size: 20px;
    }
`;

const FilterSection = styled.div`
    margin: 0 auto;
    width: max-content;
    & .form-group__label {
        color: white;
    }
`;

const Home = ({ t, i18n }) => {
    const initialValues = recoveryInitialValues(localStorage.getItem('filters'));

    return (
        <>
            <Main id="filter-section">
                <Header withBackground={false} />
                <Intro>
                    {t('home.title')}
                </Intro>
                <FilterSection>
                    <Filters
                        t={t}
                        currentLanguage={i18n.language}
                        initialValues={initialValues}
                        withRedirect
                    />
                </FilterSection>
            </Main>
            <div className="content">
                <div>
                    <Title>{t('home.collections.title')}</Title>
                </div>
                <div className="content__cards-container">
                    {/* вот здесь ссылку на карточку локации передавать как пропс */}
                    {areas.map((area) => <SmallCard {...area} key={area.name} name={t(area.name)} />)}
                </div>
                <div>
                    <Title>{t('home.services.title')}</Title>
                </div>
                <div className="content__cards-container">
                    {services.map((service) => (
                        <ServiceCard
                            link={service.value}
                            name={service.label}
                            img={<img src={service.imgUrl} alt={t(service.label)} />}
                            desc={service.desc}
                            textLink={service.textLink}
                            key={service.label}
                        />
                    ))}
                </div>
                <div>
                    <EmailTouch />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default withTranslation()(Home);
