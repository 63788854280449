import React from 'react';
import { Field } from "formik";

const CheckboxField = ({ label, name, className = '' }) => (
    <div className={`checkbox-filter ${className}`}>
        <Field id={name} name={name} type="checkbox" />
        <label htmlFor={name}>{label}</label>
    </div>
);

export default CheckboxField;
