import React from 'react';
import { withTranslation } from "react-i18next";
import Header from "$components/layout/Header";
import Layout from "$shared/components/Layout";
import Content from "$components/layout/Content";
import PhotoImage from "$img/mortgage.jpg";
// import BenefitItems from "$components/layout/BenefitItems";
import Questions from "$components/layout/Questions";
import FormFeedback from "$components/layout/FormFeedback";
import Footer from "$components/layout/Footer";
// import BenefitImage from "$img/benefit.png";

/*const benefitsForMortgagePage = [
    {
        id: 1,
        img: BenefitImage,
        title: 'mortgage.benefits.title1',
        text: 'mortgage.benefits.text1'
    },
    {
        id: 2,
        img: BenefitImage,
        title: 'mortgage.benefits.title2',
        text: 'mortgage.benefits.text2'
    },
    {
        id: 3,
        img: BenefitImage,
        title: 'mortgage.benefits.title3',
        text: 'mortgage.benefits.text3'
    }
]*/

const questionsForMortgagePage = [
    {
        id: 1,
        title: 'mortgage.questions.title1',
        text: 'mortgage.questions.text1'
    },
    {
        id: 2,
        title: 'mortgage.questions.title2',
        text: 'mortgage.questions.text2'
    },
    {
        id: 3,
        title: 'mortgage.questions.title3',
        text: 'mortgage.questions.text3'
    }
];

const Mortgage = ({ t }) => (
    <>
        <Header />
        <Layout className="partners">
            <Content
                img={PhotoImage}
                title={t('mortgage.hero.headline')}
            >
                <p>{t('mortgage.hero.paragraph1')}</p>
                <p>{t('mortgage.hero.paragraph2')}</p>
                <p>{t('mortgage.hero.paragraph3')}</p>
            </Content>
{/*            <BenefitItems benefits={benefitsForMortgagePage} />*/}
            <Questions title={t('mortgage.questions.title')} questions={questionsForMortgagePage} />
            <FormFeedback />
        </Layout>
        <Footer />
    </>
);

export default withTranslation()(Mortgage);
