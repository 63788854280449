import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from "react-i18next";
import { locations } from '$services/index';
import Select from '$shared/components/Select';
import FormGroup from "$shared/components/FormGroup";

import './styles.css';

const DropdownLocation = ({ listId = '', name, setFieldValue, t }) => {

    const [dictionary, setDictionary] = useState([]);

    useEffect(() => {
        locations.getDictionaryLocations(listId).then((data) => {
            setDictionary(data);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    const onChange = useCallback((selectedOption, selectedLocations) => {
        setFieldValue(name, selectedLocations);
    }, [name, setFieldValue]);

    const SelectMemo = useMemo(() => (
        <Select
            label={t('search.location.label')}
            placeholder={t('search.location.placeholder')}
            data={dictionary}
            onChange={onChange}
            className="location-select"
        />
    ), [onChange, dictionary, t]);

    return (
        <div className="location-select-container">
            <FormGroup
                label={t('search.location.label')}
            >
                {SelectMemo}
                <button
                    type="submit"
                    className="btn filters__submit is-desktop"
                >
                    {t('search.button')}
                </button>
            </FormGroup>
        </div>
    );
}

export default withTranslation()(DropdownLocation);
