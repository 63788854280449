import React from 'react';
import { withFormik } from "formik";
import options from "../optionsFormik";
import { useVisible } from "../useVisible";
import Filters from "./Filters";

import './styles.css';

const FiltersLocationPage = (props) => {
    const [isVisible, onShowAdvanced, onCloseOnMobile] = useVisible();
    return (
        <Filters
            {...props}
            isVisible={isVisible}
            onShowAdvanced={onShowAdvanced}
            onCloseOnMobile={onCloseOnMobile}
        />
    );
};

export default withFormik(options)(FiltersLocationPage);
