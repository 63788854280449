import React from 'react';
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const ExampleContainer = styled.div`
    height: 100vh;
    width: 100%;
    text-align: center;
    :before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
`;

const Desc = styled.div`
    display: inline-block;
    vertical-align: middle;
    border-radius: 8px;
    padding: 36px;
    background-color: #f5f5f5;
    width: 70%;
`;

const FAQ = ({ t }) => (
    <ExampleContainer>
        <Desc>
            {t('home.footer.company.faq')}
        </Desc>
    </ExampleContainer>
);

export default withTranslation()(FAQ);
