const request = (searchString = '') => new Promise((resolve, reject) => {
    const url = new URL('https://api.centsnhomes.com/api/v1/resources/locations');
    url.search = searchString;
    fetch(url).then((response) => {
        resolve(response.json());
    }).catch((error) => {
        reject(error);
    });
});

export default request;
