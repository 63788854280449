import React, { useCallback } from 'react';
import { withTranslation } from "react-i18next";
import Select from "$shared/components/Select";
import FormGroup from "$shared/components/FormGroup";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";

const PropsSize = ({ name, value, className, t, onChange, onBlur, data }) => {

    const handleChange = useCallback(({ value }) => {
        onChange(name, value);
    }, [onChange]);

    return (
        <FormGroup
            label={t('search.props_size.title')}
            classname={className}
            hint={t('search.props_size.hint')}
        >
            <FormikFastField
                component={Select}
                onChange={handleChange}
                onBlur={onBlur}
                name={name}
                className="select-size"
                mode="radioSelect"
                data={data.map(option => ({
                    ...option,
                    label: t(option.label),
                    checked: value ? Number(value) === option.id : option.checked
                }))}
                placeholder={t('search.props_size.placeholder')}
            />
        </FormGroup>
    );
}

export const options = [
    { key: 'all', id: 'all', label: 'search.labels.all', value: '', checked: true },
    { id: 1, label: 'search.props_size.s', value: 1 },
    { id: 2, label: 'search.props_size.m', value: 2 },
    { id: 3, label: 'search.props_size.b', value: 3 },
];

export default Object.assign(withTranslation()(PropsSize), {
    options
});
