import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import IntlProvider from '$i18n/IntlProvider';
import GAListener from './components/GAListener'
import { GA_ID } from "$config/appConfig";

import Cookies from '$components/Cookies';
import RouterContainer from "$pages/RouterContainer";

import './index.css';

ReactDOM.render((
    <IntlProvider>
        <BrowserRouter>
            <GAListener trackingId={GA_ID}>
                <Route path="/:locale?" component={RouterContainer} />
                <Cookies />
            </GAListener>
        </BrowserRouter>
    </IntlProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
