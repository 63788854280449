const initialValues = {
    locations: [],
    avgYield: { min: '', max: '' },
    propsPrice: { min: '', max: '' },
    populationDev:  { min: '', max: '' },
    population: { min: '', max: '' },
    vacancyRate: { min: '', max: '' },
    gdp: { min: '', max: '' },
    propsAge: '',
    propsSize: '',
    populationDevSelect: '',
    vacancyRateSelect: '',
    gdpSelect: '',
    areaSize: '',
    university: false,
    numberStudents: { min: '', max: '' },
    unemploymentRate: { min: '', max: '' },
    hospitals: false,
    stockCompanies: false,
    numberRooms: { min: '', max: '' },
    balcony: false,
    parking: false,
    builtinKitchen: false,
    accessibility: false,
};

export default initialValues;
