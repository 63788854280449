import { getPath } from "$utils/getPath";
import routes from "$routes";
import initialValues from "./initialValues";
import normalizeURL from "./normalizeURL";
import { locations } from "$services/index";

const validate = (values, { t }) => {
    const errors = {};

    const avgYieldMin = Number(values.avgYield?.min);
    const avgYieldMax = Number(values.avgYield?.max);

    if (avgYieldMin && !(avgYieldMin >= 0 && avgYieldMin <= 100)) {
        errors.avgYield = t('search.range_error', { min: 0, max: 100 });
    }

    if (avgYieldMax && !(avgYieldMax >= avgYieldMin && avgYieldMax <= 100)) {
        errors.avgYield = t('search.range_error', { min: avgYieldMin, max: 100 });
    }

    // const propsPriceMax = Number(values.propsPrice?.max);
    // const propsPriceMin = Number(values.propsPrice?.min);

    // if (propsPriceMin && !(propsPriceMin <= propsPriceMax && propsPriceMin >= 0)) {
    //     errors.propsPriceMin = t('search.range_price_error', { max: propsPriceMax });
    // }

    const popDevMin = Number(values.populationDev?.min);
    const popDevMax = Number(values.populationDev?.max);

    if (popDevMin > 100 || popDevMax > 100) {
        errors.populationDev = t('search.range_error', { min: 0, max: 100 });
    }

    const vacancyRateMin = Number(values.vacancyRate?.min);
    const vacancyRateMax = Number(values.vacancyRate?.max);

    if (vacancyRateMin > 100 || vacancyRateMax > 100) {
        errors.vacancyRate = t('search.range_error', { min: 0, max: 100 });
    }

    const gdpMin = values.gdp?.min;
    const gdpMinNumber = Number(gdpMin);
    if (gdpMin && (gdpMinNumber < 25000 || gdpMinNumber > 150000)) {
        errors.gdp = t('search.range_error', { min: 25000, max: 150000 });
    }

    const gdpMax = Number(values.gdp?.max);
    const gdpMaxNumber = Number(gdpMax);
    if (gdpMax && (gdpMaxNumber < 25000 || gdpMaxNumber > 150000)) {
        errors.gdp = t('search.range_error', { min: 25000, max: 150000 });
    }

    const numberStudentsMin = values.numberStudents?.min;
    const numberStudentsMinNumber = Number(numberStudentsMin);
    if (numberStudentsMin && (numberStudentsMinNumber < 2000 || numberStudentsMinNumber > 100000)) {
        errors.numberStudents = t('search.range_error', { min: 2000, max: 100000 });
    }

    const numberStudentsMax = Number(values.numberStudents?.max);
    const numberStudentsMaxNumber = Number(numberStudentsMax);
    if (numberStudentsMax && (numberStudentsMaxNumber < 2000 || numberStudentsMaxNumber > 100000)) {
        errors.numberStudents = t('search.range_error', { min: 2000, max: 100000 });
    }

    const unemploymentRateMin = Number(values.unemploymentRate?.min);
    const unemploymentRateMax = Number(values.unemploymentRate?.max);

    if (unemploymentRateMin > 100 || unemploymentRateMax > 100) {
        errors.unemploymentRate = t('search.range_error', { min: 0, max: 100 });
    }

    [
        'avgYield',
        'propsPrice',
        'populationDev',
        'vacancyRate',
        'numberRooms',
        'gdp',
        'numberStudents',
        'unemploymentRate'
    ].forEach((key) => {
        const min = Number(values[key].min);
        const max = Number(values[key].max);
        if (min && max && !(min <= max)) {
            errors[key] = t('search.minmax_error');
        }
    });
    console.log(errors);
    return errors;
};

const options = {
    enableReinitialize: true,
    mapPropsToValues: props => props?.initialValues || initialValues,
    validate,
    handleSubmit: (values, { setSubmitting, props: { withRedirect = false, onSubmit } }) => {
        setSubmitting(false);
        getListIdToken(values?.locations, (data) => {
            let resultValues = { ...values };
            if (data?.listId) {
                resultValues.listId = data.listId;
            }
            const searchString = new URLSearchParams(normalizeURL(resultValues)).toString();
            if (withRedirect) {
                window.location.href = `${getPath(routes.locations.value)}${
                    searchString ? `?${searchString}` : ''
                }`;
            } else {
                onSubmit(searchString);
            }
        });
    }
};

const getListIdToken = (selectedLocations = [], callback) => {
    const idsLocations = (selectedLocations || []).map(({ value }) => value);

    if (!idsLocations.length) {
        callback();
        return;
    }

    locations.getListIdToken(idsLocations).then((data) => {
        callback(data);
    }).catch((error) => {
        console.error(error);
        callback(error);
    });
}

export default options;
