import React, { useEffect } from 'react';
import { ReactComponent as CloseIcon } from "$icons/close.svg";
import { typesField } from "$shared/components/Inputs";
import { MOBILE_SCREEN_768 } from "$shared/constants";
import { getFiltersSettings, typeFilters } from "$components/Filters/filtersSettings";
import RowFilter from "../RowFilters";
import DoubleField from "../DoubleField";
import CheckboxField from "../CheckboxField";
import AdvancedFiltersFooter from "./AdvancedFiltersFooter";

import './styles.css';

const AdvancedFilters = ({
    values, setFieldValue, t, onShowAdvanced, onCloseOnMobile, isVisible, isLocationPage = false
}) => {
    useEffect(() => {
        const onResize = () => {
            if (isVisible) {
                if (window.innerWidth > MOBILE_SCREEN_768) {
                    document.body.style.overflow = '';
                } else {
                    document.body.style.overflow = 'hidden';
                }
            }
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [isVisible]);

    const amenitiesFiltersOnLocationPage = getFiltersSettings(
        typeFilters.lookingFor, { values, setFieldValue, t }
    )
        .reverse()
        .splice(0, 2)
        .map(item => ({ ...item, isMobile: true }));

    const advancedFilters = [
        {
            id: 'looking_for',
            label: t('search.rows.looking_for'),
            isMobile: true,
            isMobileLabel: true,
            filters: getFiltersSettings(typeFilters.lookingForAdvanced, { values, setFieldValue, t })
        },
        {
            id: 'macro',
            label: t('search.rows.macro'),
            filters: [
                {
                    Component: DoubleField,
                    name: 'populationDev',
                    typeField: typesField.INTEGER,
                    label: t('search.pop_dev.title'),
                    hint: t('search.pop_dev.hint'),
                    placeholder: {
                        min: t('search.labels.min'),
                        max: t('search.labels.max')
                    }
                },
                {
                    Component: DoubleField,
                    name: 'vacancyRate',
                    typeField: typesField.FLOAT,
                    label: t('search.vacancy.title'),
                    hint: t('search.vacancy.hint'),
                    placeholder: {
                        min: t('search.labels.min'),
                        max: t('search.labels.max')
                    },
                },
                {
                    Component: DoubleField,
                    name: 'gdp',
                    typeField: typesField.INTEGER,
                    label: t('search.gdp.title'),
                    hint: t('search.gdp.hint'),
                    placeholder: {
                        min: t('search.labels.min'),
                        max: t('search.labels.max')
                    },
                },
                {
                    Component: DoubleField,
                    name: 'population',
                    typeField: typesField.INTEGER,
                    label: t('search.area_size.title'),
                    hint: t('search.area_size.hint'),
                    placeholder: {
                        min: t('search.labels.min'),
                        max: t('search.labels.max')
                    }
                },
                {
                    Component: DoubleField,
                    name: 'numberStudents',
                    typeField: typesField.INTEGER,
                    label: t('search.number_students.title'),
                    hint: t('search.number_students.hint'),
                    placeholder: {
                        min: t('search.labels.min'),
                        max: t('search.labels.max')
                    },
                },
                {
                    Component: DoubleField,
                    name: 'unemploymentRate',
                    typeField: typesField.FLOAT,
                    label: t('search.unemployment_rate.title'),
                    hint: t('search.unemployment_rate.hint'),
                    placeholder: {
                        min: t('search.labels.min'),
                        max: t('search.labels.max')
                    },
                },
                {
                    type: 'subrow',
                    id: 'macro_sub',
                    classname: 'checkboxes-container',
                    filters: [
                        isLocationPage && {
                            Component: CheckboxField,
                            name: 'university',
                            label: t('search.university')
                        },
                        {
                            Component: CheckboxField,
                            name: 'hospitals',
                            label: t('search.hospitals')
                        },
                        {
                            Component: CheckboxField,
                            name: 'stockCompanies',
                            label: t('search.stock_list_companies')
                        }
                    ]
                }
            ]
        },
        {
            id: 'property',
            label: t('search.rows.property'),
            isManyFilters: true,
            filters: [
                ...amenitiesFiltersOnLocationPage,
                {
                    Component: DoubleField,
                    name: 'numberRooms',
                    typeField: typesField.INTEGER,
                    label: t('search.number_rooms.title'),
                    hint: t('search.number_rooms.hint'),
                    placeholder: {
                        min: t('search.labels.min'),
                        max: t('search.labels.max')
                    },
                },
                {
                    type: 'subrow',
                    classname: 'checkboxes-container',
                    id: 'property_sub',
                    filters: [
                        {
                            Component: CheckboxField,
                            name: 'balcony',
                            label: t('search.balcony')
                        },
                        {
                            Component: CheckboxField,
                            name: 'parking',
                            label: t('search.parking')
                        },
                        {
                            Component: CheckboxField,
                            name: 'builtinKitchen',
                            label: t('search.builtin_kitchen')
                        },
                        {
                            Component: CheckboxField,
                            name: 'accessibility',
                            label: t('search.accessibility')
                        }
                    ]
                }
            ]
        }
    ];

    return (
        <div className="advanced-filters-container">
            <CloseIcon
                className="advanced-filters-container__close"
                onClick={onShowAdvanced}
            />
            <div className="advanced-filters-container__content">
                {advancedFilters.filter(Boolean).map((row) => (
                    <RowFilter {...row} key={row.id} />
                ))}
            </div>
            <AdvancedFiltersFooter
                t={t}
                onShowAdvanced={onShowAdvanced}
                onCloseOnMobile={onCloseOnMobile}
            />
        </div>
    );
};

export default AdvancedFilters;
