import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from '$shared/components/Logo';
import { getPath } from "$utils/getPath";
import routes from "$routes";

const Footer = ({ t }) => (
    <footer>
        <div className="footer__container">
            <div className="footer__block">
                <Logo />
                <span className="footer__block__email">
                <span className="footer__block__email-label">{t('footer.mail.mail')} </span>
                <a
                    className="footer__block__email-value footer__block__item"
                    href="mailto:info@centsnhomes.com">
                    {t('footer.mail.address')}
                </a>
            </span>
            </div>
            <div className="footer__link-container">
                <div className="footer__block">
                    <span className="footer__block__label">{t('footer.company.title')}</span>
                    <Link to={getPath(routes.team.value)} className="footer__block__item">
                        {t('footer.company.team')}
                    </Link>
                    <Link to={getPath(routes.about.value)} className="footer__block__item">
                        {t('footer.company.about')}
                    </Link>
                    <Link to={getPath(routes.faq.value)} className="footer__block__item">

                    </Link>
                    <Link to={getPath(routes.contacts.value)} className="footer__block__item">
                        {t('footer.company.contact')}
                    </Link>
                </div>
                <div className="footer__block">
                    <span className="footer__block__label">{t('footer.services.title')}</span>
                    <Link to={getPath(routes.mortgage.value)} className="footer__block__item">
                        {t('footer.services.mortgage')}
                    </Link>
                    <Link to={getPath(routes.consultation.value)} className="footer__block__item">
                        {t('footer.services.consultation')}
                    </Link>
                    <Link to={getPath(routes.appraisal.value)} className="footer__block__item">
                        {t('footer.services.appraisal')}
                    </Link>
                    <Link to={getPath(routes.partners.value)} className="footer__block__item">
                        {t('footer.services.partner')}
                    </Link>
                </div>
                <div className="footer__block">
                    <span className="footer__block__label">{t('footer.legal.title')}</span>
                    <Link to={getPath(routes.terms.value)} className="footer__block__item">
                        {t('footer.legal.terms')}
                    </Link>
                    <Link to={getPath(routes.privacy.value)} className="footer__block__item">
                        {t('footer.legal.data')}
                    </Link>
                    <Link to={getPath(routes.imprint.value)} className="footer__block__item">
                        {t('footer.legal.imprint')}
                    </Link>
                </div>
            </div>
            <span className="footer__block__rights">
                {t('footer.misc.rights')},<br />
                {t('footer.misc.before_love')} <span role="img" aria-label="with love">❤</span>️ {t('footer.misc.after_love')}.
            </span>
        </div>
    </footer>
);

export default withTranslation()(Footer);
