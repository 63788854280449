import React from 'react';
import { withTranslation } from "react-i18next";
import Header from "../../components/layout/Header";
import Index from "../../shared/components/Layout";
import FormFeedback from "../../components/layout/FormFeedback";
import Footer from "../../components/layout/Footer";

const Contact = ({ t }) => (
    <>
        <Header />
        <Index className="partners">
            <FormFeedback />
        </Index>
        <Footer />
    </>
);

export default withTranslation()(Contact);
