export const LANGUAGES = {
    en: 'en',
    de: 'de',
    ru: 'ru',
};

export const LANGUAGES_FULL_NAMES = {
    en: 'languages.en',
    de: 'languages.de',
    ru: 'languages.ru',
};

export const ARRAY_AVAILABLE_LANGUAGES = [
    LANGUAGES.en,
    LANGUAGES.de,
    LANGUAGES.ru,
];

// export const IS_BETA_MOD = true;
export const IS_BETA_MOD = false;

export const GA_ID = 'UA-162604715-1';
