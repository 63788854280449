import React, { useEffect } from 'react';

const ListColumns = ({ namesColumns, listColumns, setColumns, setVisible }) => {
    const handleClickOutside = (event) => {
        if (!event.target.closest('#list-result-columns')) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, []);

    return (
        <ul id="list-result-columns" className="locations-page__results__list-columns">
            {Object.keys(listColumns).map((key) => {
                const state = listColumns[key];
                return (
                    <li className="locations-page__results__list-columns__item" key={key}>
                        <span>{namesColumns[key]}</span>
                        <input
                            type="checkbox"
                            checked={state}
                            onChange={() => setColumns(key)}
                        />
                    </li>
                )
            })}
        </ul>
    );
};

export default ListColumns;
