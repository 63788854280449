import React from 'react';
import { withTranslation } from "react-i18next";
import Select from "$shared/components/Select";
import FormGroup from "$shared/components/FormGroup";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";

const CUSTOM_ID = 'custom';

const PopulationDev = ({ name, data, onChange, onBlur, className, t }) => (
    <FormGroup
        label={t('search.pop_dev.title')}
        classname={className}
        hint={t('search.pop_dev.hint')}
    >
        <FormikFastField
            component={Select}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            mode="radioSelect"
            data={data}
            placeholder={t('search.pop_dev.placeholder')}
        />
    </FormGroup>
);

const dependencyFiledName = 'populationDev';
const options = [
    {
        id: 'all',
        label: 'search.labels.all',
        value: '',
        dependencyValue: { min: '', max: '' }
    },
    {
        id: 'stable',
        label: 'search.pop_dev.stable',
        value: 'stable',
        dependencyValue: { min: 0, max: '' }
    },
    {
        id: 'rapidly',
        label: 'search.pop_dev.rapidly',
        value: 'rapidly',
        dependencyValue: { min: 3, max: '' }
    },
    {
        id: CUSTOM_ID,
        label: 'search.labels.custom',
        value: ''
    }
];

export default Object.assign(withTranslation()(PopulationDev), {
    dependencyFiledName,
    options
});
