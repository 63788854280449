import React from 'react';
import NumberWrapper from "./NumberWrapper";

const NumberInput = ({ onChange, ...props }) => {
    const classname = [props.classname, props.error && 'is-error'].filter(Boolean).join(' ');
    return (
        <input
            {...props}
            onChange={onChange}
            className={classname}
        />
    );
};

export default NumberWrapper(NumberInput);
