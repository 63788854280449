import React from 'react';
import _ from 'lodash';

const FieldWrapper = ({
  field, form, fieldComponent, ...props
}) => {
  const { name } = field;
  const error = _.get(form.touched, name) && _.get(form.errors, name);
  const Component = fieldComponent;
  return (
    <Component {...field} {...props} form={form} error={error} />
  );
};


export default FieldWrapper;
