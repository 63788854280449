import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactComponent as InfoIcon } from '$icons/info.svg';

import './styles.css';

const Hint = ({ text, containerClassName, className, children }) => {
    const [isVisible, setVisible] = useState(false);
    const buttonRef = useRef(null);
    const popperRef = useRef(null);
    const [arrowRef, setArrowRef] = useState(null);
    const { styles, attributes } = usePopper(
        buttonRef.current,
        popperRef.current,
        {
        placement: 'top-start',
        modifiers: [
            {
                name: 'arrow',
                options: { element: arrowRef },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });

    const onMouseEnter = () => setVisible(true);
    const onMouseLeave = () => setVisible(false);

    return (
        <>
            <div
                className={['hint', containerClassName].join(' ')}
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
            >
                {children || (
                    <InfoIcon
                        ref={buttonRef}
                    />
                )}
            </div>
            {isVisible && (
                <div
                    className={['hint-text', className].join(' ')}
                    ref={popperRef}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    {text}
                    <div ref={setArrowRef} style={styles.arrow} />
                </div>
            )}
        </>
    );
};

export default Hint;
