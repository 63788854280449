import React from 'react';
import Filters from "./Filters";
import { withFormik } from "formik";
import options from "./optionsFormik";
import { useVisible } from "./useVisible";

import './styles.css';

const FiltersContainer = (props) => {
    const [isVisible, onShowAdvanced] = useVisible();
    return (<Filters {...props} isVisible={isVisible} onShowAdvanced={onShowAdvanced} />);
};

export default withFormik(options)(FiltersContainer);
