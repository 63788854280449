import React from 'react';

const SubRow = ({ option }) => (
    <div
        className={option?.classname || ''}
    >
        {option
            .filters
            .filter(Boolean).map(({ Component, classname = '', ...filter }) => (
                <Component
                    {...filter}
                    className={`filters__filter ${classname}`}
                    key={filter.name}
                />
            ))}
    </div>
);

export default SubRow;
