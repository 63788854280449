import i18n from "i18next";
import React from "react";
import detector from "i18next-browser-languagedetector";
import { I18nextProvider, initReactI18next } from "react-i18next";


// import { connect } from "react-redux";
// import { Dispatch } from "redux";

// import { IApplicationState } from "../store";
// import { changeLanguage } from "../store/i18n/actions";
// import markdownProcessor from "./markdownProcessor";

import resources from "./resources";

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en",
        whitelist: ['en', 'de', 'ru'],
        keySeparator: '.', // we do not use keys in form messages.welcome
        detection: {
            lookupQuerystring: 'lang',
            lookupCookie: 'lang',
            lookupLocalStorage: 'lang',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

const IntlProvider = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default IntlProvider;

