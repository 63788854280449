import React from 'react';

const AdvancedFiltersFooter = ({ t, onShowAdvanced, onCloseOnMobile }) => (
    <div className="advanced-filters-container__footer">
        <button
            onClick={onShowAdvanced}
            className="btn cancel"
        >
            {t('search.button_cancel')}
        </button>
        <button
            className="btn"
            type="submit"
            onClick={onCloseOnMobile || undefined}
        >
            {t('search.button')}
        </button>
    </div>
);

export default AdvancedFiltersFooter;
