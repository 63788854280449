import React from 'react';
import classnames from 'classnames';
import SubRow from "./SubRow";

const RowFilter = ({
    label,
    filters,
    classname = '',
    labelClassname = '',
    isMobile = false,
    isMobileLabel = false,
    isDesktop = false,
    isDesktopLabel = false,
    isManyFilters = false,
}) => {
    const labelClassName = classnames(
        'filters__row-title',
        labelClassname,
        isMobileLabel && 'is-mobile',
        isDesktopLabel && 'is-desktop',
    );

    const rowClassName = classnames(
        'filters__row',
        classname,
        isMobile && 'is-mobile',
        isDesktop && 'is-desktop',
        (filters.length > 4 || isManyFilters) && 'is-many-filters'
    );
    return (
        <>
            {label && (
                <div className={labelClassName}>{label}</div>
            )}
            <div className={rowClassName}>
                {filters.filter(Boolean).map(({
                  Component,
                  classname = '',
                  isMobile = false,
                  isDesktop = false,
                  ...filterOption
                }) => {
                    if (filterOption?.type === 'subrow') {
                        return (<SubRow option={{ ...filterOption, classname }} key={filterOption.id} />);
                    }

                    const filterClassName = classnames(
                        'filters__filter',
                        classname,
                        isMobile && 'is-mobile',
                        isDesktop && 'is-desktop'
                    );

                    return (
                        <Component
                            {...filterOption}
                            className={filterClassName}
                            key={filterOption.name}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default RowFilter;
