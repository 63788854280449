import React from 'react';
import { withTranslation } from "react-i18next";
import Header from "../../components/layout/Header";
import Layout from "../../shared/components/Layout";
import Content from "../../components/layout/Content";
import PhotoImage from "../../img/appraisal.jpg";
// import BenefitItems from "../../components/layout/BenefitItems";
import Questions from "../../components/layout/Questions";
import FormFeedback from "../../components/layout/FormFeedback";
import Footer from "../../components/layout/Footer";

// import BenefitImage from "../../img/benefit.png";

/*const benefitsForAppraisalPage = [
    {
        id: 1,
        img: BenefitImage,
        title: 'appraisal.benefits.title1',
        text: 'appraisal.benefits.text1'
    },
    {
        id: 2,
        img: BenefitImage,
        title: 'appraisal.benefits.title2',
        text: 'appraisal.benefits.text2'
    },
    {
        id: 3,
        img: BenefitImage,
        title: 'appraisal.benefits.title3',
        text: 'appraisal.benefits.text3'
    }
]*/

const questionsForAppraisalPage = [
    {
        id: 1,
        title: 'appraisal.questions.title1',
        text: 'appraisal.questions.text1'
    },
    {
        id: 2,
        title: 'appraisal.questions.title2',
        text: 'appraisal.questions.text2'
    },
    {
        id: 3,
        title: 'appraisal.questions.title3',
        text: 'appraisal.questions.text3'
    }
];

const Appraisal = ({ t }) => (
    <>
        <Header />
        <Layout className="partners">
            <Content
                img={PhotoImage}
                title={t('appraisal.hero.headline')}
            >
                <p>{t('appraisal.hero.paragraph1')}</p>
                <p>{t('appraisal.hero.paragraph2')}</p>
                <p>{t('appraisal.hero.paragraph3')}</p>
            </Content>
{/*            <BenefitItems benefits={benefitsForAppraisalPage} />*/}
            <Questions title={t('appraisal.questions.title')} questions={questionsForAppraisalPage} />
            <FormFeedback />
        </Layout>
        <Footer />
    </>
);

export default withTranslation()(Appraisal);
