import routes from "$routes";
import MortgageImage from "./ServiceCard/img/mortage.png";
import ConsultationImage from "./ServiceCard/img/consultation.png";
import AppraisalImage from "./ServiceCard/img/appraisal.png";

export { default } from './ServiceCard/index';

export const services = [
    {
        id: '0',
        label: 'home.services.mortgage.label',
        menulabel: 'header.mortgage',
        value: routes.mortgage.value,
        imgUrl: MortgageImage,
        desc: 'home.services.mortgage.desc',
        textLink: 'home.services.mortgage.textLink'
    },
    {
        id: '1',
        label: 'home.services.consultation.label',
        menulabel: 'header.consultation',
        value: routes.consultation.value,
        imgUrl: ConsultationImage,
        desc: 'home.services.consultation.desc',
        textLink: 'home.services.consultation.textLink'
    },
    {
        id: '2',
        label: 'home.services.appraisal.label',
        menulabel: 'header.appraisal',
        value: routes.appraisal.value,
        imgUrl: AppraisalImage,
        desc: 'home.services.appraisal.desc',
        textLink: 'home.services.appraisal.textLink'
    }
];
