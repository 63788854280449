import { LANGUAGES } from "$config/appConfig";
import i18next from 'i18next';

export const getPath = (path) => {
    if (!path) {
        return '';
    }

    const currentLang = i18next.language;
    if (currentLang === LANGUAGES.en) {
        return path.replace(/\/:locale\?\/?/g, '/');
    }
    return path.replace(/:locale\?/g, `${currentLang}`);
};
