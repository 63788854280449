import React from 'react';
import { ReactComponent as DropdownIcon } from "$icons/dropdown.svg";
import { ReactComponent as OptionsIcon } from "$icons/options.svg";

const AdvancedFilterShow = ({ t, onShowAdvanced, isVisible }) => (
    <div className="advanced-filters-action">
        <button
            className="btn link advanced-filters-action__btn"
            onClick={onShowAdvanced}
        >
            <DropdownIcon
                className={`advanced-filters-action__icon ${isVisible && 'is-rotate'}`}
            />
            <OptionsIcon
                className="advanced-filters-action__icon is-mobile"
            />
            {t('search.advanced')}
        </button>
    </div>
);

export default AdvancedFilterShow;
