import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const SimpleSelect = ({
    label,
    labelClassname,
    dropDownClassname,
    className,
    options = [],
    isCloseByOuterClick = true,
    ...props
}) => {
    const [isOpen, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    useEffect(() => {
        if (isCloseByOuterClick) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [isCloseByOuterClick]);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    const onOpen = useCallback(() => {
        setOpen(!isOpen);
    }, [isOpen]);

    const onClickItem = useCallback((...args) => {
        if (props.onChange) {
            props.onChange(...args);
        }
    }, [props]);

    const selectClassName = classnames('select', className);
    const labelClassName = classnames('select-label-wrapper', labelClassname);
    const dropDownClassName = classnames('select-dropdown', dropDownClassname);

    return (
        <div ref={wrapperRef} className={selectClassName}>
            <div onClick={onOpen} className={labelClassName}>
                <span className="select-label">{label || '—'}</span>
                <div className="select-cursor" />
            </div>
            {isOpen && (
                <div className={dropDownClassName}>
                    {options && options.map(({ id, label, value, children }) => children ? (
                        <div className="select-dropdown-item" key={id}>{children}</div>
                    ) : (
                        <div
                            className="select-dropdown-item" key={id}
                            onClick={() => onClickItem(value, { id, label, value })}
                        >
                            {label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

SimpleSelect.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    labelClassname: PropTypes.string,
    className: PropTypes.string,
    dropDownClassname: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        label: PropTypes.oneOfType([
            PropTypes.string,
        ]),
        children: PropTypes.node
    }))
};

SimpleSelect.defaultProps = {
    label: '',
    labelClassname: '',
    className: '',
    options: []
};

export default SimpleSelect;
