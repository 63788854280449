import en from "./en.json";
import de from "./de.json";
import ru from './ru.json';

const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  ru: {
    translation: ru
  }
};

// задел на автоматизацию.
// ARRAY_AVAILABLE_LANGUAGES.forEach( lang => {
//   resources[ lang ].translation = (import lang from `./${lang}.json`);
// });


export default resources;
