import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

import './styles.css';

const SmallCard = ({
    name,
    imgUrl,
    yieldParam,
    population,
    classname,
    t,
    url
}) => (
    <a className="location-card small" href={url}>
        <div style={{
            background: `url(${imgUrl}) no-repeat center center`,
            backgroundSize: "cover"
        }} className="location-card__image" />
        <span className="location-card__name">{t(name)}</span>
        <div className="location-card__params">
            <div className="location-card__params__row">
                <span className="location-card__params__row__label">{t('home.collections.yield')}</span>
                <span className="location-card__params__row__value">{yieldParam} %</span>
            </div>
            <div className="location-card__params__row">
                <span className="location-card__params__row__label">{t('home.collections.pop_dev')}</span>
                <span className="location-card__params__row__value">{population} %</span>
            </div>
        </div>
    </a>
);

SmallCard.propTypes = {
    name: PropTypes.string,
    imgUrl: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    yieldParam: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    population: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    classname: PropTypes.string,
    t: PropTypes.func.isRequired
};

SmallCard.defaultProps = {
    name: '—',
    imgUrl: null,
    yieldParam: 0,
    population: 0,
    classname: '',
};

export default withTranslation()(SmallCard);
