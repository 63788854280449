import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { ARRAY_AVAILABLE_LANGUAGES, LANGUAGES, LANGUAGES_FULL_NAMES } from "$config/appConfig";
import SimpleSelect from "$shared/components/SimpleSelect";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const optionsLanguage = ARRAY_AVAILABLE_LANGUAGES.map((lang, index) => ({
    id: index,
    label: lang.toUpperCase(),
    value: lang
}));

const optionsMobileLanguage = t => Object.keys(LANGUAGES_FULL_NAMES).map((key, index) => ({
    id: index,
    label: t(LANGUAGES_FULL_NAMES[key]),
    value: key
}));

const LanguageSwitcher = ({ isMobile = false, t, i18n, history }) => {
    const langFromProp = LANGUAGES[i18n.language] || LANGUAGES.en;
    const [currentLanguage, setLanguage] = useState(langFromProp);

    useEffect(() => {
        setLanguage(langFromProp);
    }, [langFromProp]);

    const changeLanguage = (lng) => {
        if (lng === i18n.language) {
            return;
        }

        const currentPath = window.location.pathname;
        const searchString = window.location.search;

        if (lng === LANGUAGES.en) {
            i18n.changeLanguage(lng);
            history.push(`${currentPath.replace(/\/ru|\/de?/g, '')}${searchString}`);
            return;
        }

        if (i18n.language === LANGUAGES.en) {
            i18n.changeLanguage(lng);
            const regexp = new RegExp(/\/en?/g);
            const isLangInUrl = currentPath.match(regexp);
            if (isLangInUrl) {
                history.push(`/${currentPath.replace(/\/en?/g, lng)}`);
                return;
            }
            history.push(`/${lng}${currentPath.length > 1 ? currentPath : ''}${searchString}`);
            return;
        }

        i18n.changeLanguage(lng);
        history.push(`${currentPath.replace(/en|ru|de?/g, lng)}${searchString}`);
    };

    const classname = useMemo(() => classnames(
        'header__lang',
        isMobile && 'is-mobile'
    ), [isMobile]);

    const labelClassname = useMemo(() => classnames(
        'header__lang-label',
        isMobile && 'is-mobile'
    ), [isMobile]);

    const dropDownClassname = useMemo(() => classnames(
        'header__lang-dropdown',
        isMobile && 'is-mobile'
    ), [isMobile]);

    return (
        <SimpleSelect
            label={isMobile ? t(LANGUAGES_FULL_NAMES[currentLanguage]) : currentLanguage.toUpperCase()}
            options={isMobile ? optionsMobileLanguage(t) : optionsLanguage}
            onChange={changeLanguage}
            className={classname}
            labelClassname={labelClassname}
            dropDownClassname={dropDownClassname}
            isCloseByOuterClick={!isMobile}
        />
    );
};

export default withRouter(withTranslation()(LanguageSwitcher));
