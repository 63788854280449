import { useState } from "react";
import { MOBILE_SCREEN_768 } from "$shared/constants";

export const useVisible = () => {
    const [isVisible, setVisible] = useState(false);
    const onShowAdvanced = (event) => {
        event.preventDefault();
        if (window.innerWidth <= MOBILE_SCREEN_768 && !isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        setVisible(!isVisible);
    };

    const onCloseOnMobile = () => {
        setVisible(false);
        document.body.style.overflow = '';
    }

    return [
        isVisible,
        onShowAdvanced,
        onCloseOnMobile
    ]
};
