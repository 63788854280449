const request = (values) => new Promise((resolve, reject) => {
    window.Pageclip.send(
        '1B4CdFmHG13VJ23jfqkcTZwR2v1DeNoQ',
        'contact-form',
        {
            pathname: window.location.pathname,
            ...values
        },
        (error, response) => {
            if (error || response?.data !== 'ok') {
                reject(error);
            }
            resolve(response);
        }
    )
});

export default request;
