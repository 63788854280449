import React from 'react';
import { Field } from 'formik';
import FieldWrapper from './FieldWrapper';

export const FormikFastField = ({
  component,
  ...props
}) => (
  <Field
    {...props}
    component={FieldWrapper}
    fieldComponent={component}
  />
);

export default FormikFastField;
