import React from 'react';
import { withTranslation } from "react-i18next";
import Header from "../../components/layout/Header";
import Layout from "../../shared/components/Layout";
import Content from "../../components/layout/Content";
import Footer from "../../components/layout/Footer";

import './styles.css';

const Imprint = ({ t }) => (
    <>
        <Header />
        <div className="imprint-page-container">
            <Layout className="imprint">
                <Content
                    containerClass="first-content"
                    title={t('imprint.headline')}
                >
                <p>
                    {t('imprint.company')} <br/>
                    {t('imprint.name')} <br/>
                    {t('imprint.address1')} <br/>
                    {t('imprint.address2')} <br/>
                    {t('imprint.city')} <br/>
                    <b>{t('imprint.email_word')}:</b> {t('imprint.email')}
                </p>
                </Content>
            </Layout>
            <Footer />
        </div>
    </>
);

export default withTranslation()(Imprint);
