import React from 'react';
import QuestionItem from "./QuestionItem";
import './styles.css';

const Questions = ({
    title = 'Questions',
    questions,
    containerClassName = '',
    t
}) => {

    return (
        <div className={`question-container ${containerClassName}`}>
            <h1 className="question-container__title">{title}</h1>
            <ul className="question-container__list">
                {questions.map((question) => (
                    <QuestionItem {...question} key={question.id} />
                ))}
            </ul>
        </div>
    );
};

export default Questions;
