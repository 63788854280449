import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getPath } from "$utils/getPath";

import './styles.css';

const ServiceCard = ({ link, img, name, desc, textLink, t, i18n }) => (
    <Link
        className={`service-card ${i18n.language}`}
        to={getPath(link)}
    >
        {img}
        <div className="service-card__info-block">
            <h3 className="service-card__name">{t(name)}</h3>
            <span className="service-card__desc">{t(desc)}</span>
            <span className="service-card__link">{t(textLink)}</span>
        </div>
    </Link>
);

export default withTranslation()(ServiceCard);
