import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const Title = ({ children, classname = '' }) => (
    <span className={`title ${classname}`}>{children}</span>
);

Title.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
    classname: PropTypes.string
};

Title.defaultProps = {
    children: null,
    classname: ''
};

export default Title;
