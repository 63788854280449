import React from 'react';
import classnames from 'classnames';
import './styles.css';

const Button = ({
    type = 'default',
    classname = null,
    children,
    onClick,
    loading = false
}) => {

    const btnClassName = classnames(
        'btn',
        loading && 'btn_loading',
        classname
    );

    return (
        <button
            type={type}
            className={btnClassName}
            onClick={onClick}
        >
            {!loading && children}
            {loading && (
                <div className="loader" />
            )}
        </button>
    )
};

export default Button;
