import React, { useState } from 'react';
import NumberWrapper from "./NumberWrapper";
import './styles.css';

const DoubleInput = ({ name, value, placeholder, error, onChange, ...props }) => {
    const [isActive, setActive] = useState(false);
    const onFocus = () => setActive(true);
    const onBlur = () => setActive(false);
    const classname = [
        'double-input-container',
        error && 'is-error',
        isActive && 'is-active'
    ].join(' ');

    const min = value?.min == null ? '' : value?.min;
    const max = value?.max == null ? '' : value?.max;
    return (
        <div className={classname} tabIndex="-1">
            <span className="double-input__item">
                <input
                    {...props}
                    placeholder={placeholder.min}
                    value={min}
                    name={`${name}.min`}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </span>
            <span className="double-input__item">
                <input
                    {...props}
                    placeholder={placeholder.max}
                    value={max}
                    name={`${name}.max`}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </span>
        </div>
    )
};

export default NumberWrapper(DoubleInput);
