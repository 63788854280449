import React from 'react';
import { ReactComponent as LogoImage } from '$img/logo.svg';
import { getPath } from "$utils/getPath";
import routes from "$routes";

const Logo = () => (
    <a href={getPath(routes.main.value)} onClick={() => localStorage.setItem('filters', null)}>
        <LogoImage />
    </a>
);

export default Logo;
