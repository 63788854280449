import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import 'antd/lib/spin/style/index.css';
import classnames from 'classnames';
import { withTranslation } from "react-i18next";
import { locations } from "$services";
import numberWithSpaces from "$utils/numberWithSpaces";
import { MOBILE_SCREEN_768, MOBILE_SCREEN_900 } from "$shared/constants";
import Hint from "$shared/components/Hint";
import Layout from "$shared/components/Layout";
import { ReactComponent as SortIcon } from "$icons/sort.svg";
import { ReactComponent as FilterIcon } from "$icons/filter.svg";
import { ReactComponent as UniversityIcon } from '$icons/university.svg';
import Header from "$components/layout/Header";
import Footer from "$components/layout/Footer";
import FiltersLocationPage from "$components/Filters/FiltersLocationPage/index";
import recoveryInitialValues from "$components/Filters/recoveryInitialValues";
import NoData from "./NoData";
import ListColumns from './ListColumns';

import './styles.css';

const compare = (a, b, field) => {
    if (a[field] < b[field]) { return -1; }
    if (a[field] > b[field]) { return 1; }
    return 0;
};

const renderTitle = (title, dataIndex, sort) => {
    const className = classnames(
        'table-header',
        sort?.sortOrder === 'ascend' && sort?.sortColumn.dataIndex === dataIndex && 'is-ascend-sort',
        sort?.sortOrder === 'descend' && sort?.sortColumn.dataIndex === dataIndex && 'is-descend-sort'
    );
    return (
        <div className={className}>
            <span>{title}</span>
            <SortIcon className="table-header__icon" />
        </div>
    );
};

const isMobile = () => {
    const orientation = (window.screen.orientation || {}).type
        || window.screen?.mozOrientation?.type
        || window.screen?.msOrientation?.type;

    return window.innerWidth <= MOBILE_SCREEN_768
    || (window.innerWidth <= MOBILE_SCREEN_900 && orientation === 'landscape-primary');
};

const getPageSize = () => isMobile() ? 6 : 24;
const getColumns = () => isMobile() ? mobileSelectedColumns : defaultSelectedColumns;

const defaultSelectedColumns = {
    location: true,
    state: true,
    avg_yield: true,
    avg_price: true,
    avg_rent_price: false,
    pop_development: true,
    vacancy_rate: true,
    unemployment_rate: true,
    gdp: false,
    students: false,
    population: false,
};

const mobileSelectedColumns = {
    ...defaultSelectedColumns,
    avg_price: false,
    vacancy_rate: false,
    unemployment_rate: false,
    gdp: false,
    students: false,
    population: false,
};

const Locations = ({ t }) => {
    const [statusesLoading, setStatusesLoading] = useState({
        isLoading: false,
        isLoaded: false,
        isError: false
    });

    const [data, setData] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState(getColumns());

    const getColumnsForSelect = () => {
        const { location, state, ...columns } = selectedColumns;
        return columns;
    }

    const [isVisibleListColumn, setVisibleListColumn] = useState(false);
    const [pageSize, setPageSize] = useState(getPageSize());

    const initialValues = recoveryInitialValues(window.location.search);

    useEffect(() => {
        const onChangePageSize = () => {
            setPageSize(getPageSize());
            setSelectedColumns(getColumns());
        };
        localStorage.setItem('filters', window.location.search);
        fetchLocations(window.location.search, true);

        window.addEventListener('resize', onChangePageSize);

        return () => {
            window.removeEventListener('resize', onChangePageSize);
        };
    }, []);

    const fetchLocations = (searchString, isInit = false) => {
        setStatusesLoading( { isLoading: true, isError: false, isLoaded: false });
        if (!isInit) {
            replaceQueryString(searchString);
        }

        locations.getLocations(searchString).then((data) => {
            setStatusesLoading( { isLoading: false, isError: false, isLoaded: true });
            setData(data);
        }).catch((error) => {
            setStatusesLoading( { isLoading: false, isError: true, isLoaded: true });
            console.error(error);
        });
    };

    const replaceQueryString = (searchString) => {
        window.history.replaceState(
            null,
            null,
            searchString ? `?${searchString}` : window.location.pathname
        );
    };

    const namesColumns = {
        location: t('search.results.columns.location'),
        state: t('search.results.columns.state'),
        avg_yield: t('search.results.columns.avg_yield'),
        avg_price: t('search.results.columns.avg_price'),
        avg_rent_price: t('search.results.columns.avg_rent_price'),
        pop_development: t('search.results.columns.pop_development'),
        vacancy_rate: t('search.results.columns.vacancy_rate'),
        unemployment_rate: t('search.results.columns.unemployment_rate'),
        gdp: t('search.results.columns.gdp'),
        students: t('search.results.columns.students'),
        population: t('search.results.columns.population'),
    };

    const isVisibleState = selectedColumns.state;
    const columns = [
        {
            title: (sortOrder) => renderTitle(namesColumns.location, 'location', sortOrder),
            dataIndex: 'location',
            sorter: {
                compare: (a, b) => compare(a, b, 'location_id')
            },
            render: (value, { state, university }) => {
                return ({
                    children: (
                        <div className="locations-page__results__locations-container">
                            <div className="locations-page__results__location">{value}</div>
                            {isVisibleState && (
                                <div className="locations-page__results__state">{state}</div>
                            )}
                            {university ? (
                                <Hint
                                    text="University city"
                                    containerClassName="locations-page__results__university-hint-container"
                                    className="locations-page__results__university-hint"
                                >
                                    <UniversityIcon className="locations-page__results__university" />
                                </Hint>
                            ) : null}
                        </div>
                    ),
                    props: { colSpan: isVisibleState ? 2 : 1 },
                })
            },
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.state, 'state', sortOrder),
            dataIndex: 'state',
            sorter: {
                compare: (a, b) => compare(a, b, 'state_id')
            },
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.avg_yield, 'avg_yield', sortOrder),
            dataIndex: 'avg_yield',
            render: value => (
                <div>
                    <span className="cell__label">{t('search.results.columns.mobile.avg_yield')}</span>
                    <span className="cell__value">{`${value} %`}</span>
                </div>
            ),
            sorter: {
                compare: (a, b) => {
                    return Number(a.avg_yield) - Number(b.avg_yield);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.avg_price, 'avg_price', sortOrder),
            dataIndex: 'avg_price',
            render: value => numberWithSpaces(value),
            sorter: {
                compare: (a, b) => {
                    return Number(a.avg_price) - Number(b.avg_price);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.avg_rent_price, 'avg_rent_price', sortOrder),
            dataIndex: 'avg_rent_price',
            render: value => numberWithSpaces(value),
            sorter: {
                compare: (a, b) => {
                    return Number(a.avg_rent_price) - Number(b.avg_rent_price);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.pop_development, 'pop_development', sortOrder),
            dataIndex: 'pop_development',
            render: value => (
                <div>
                    <span className="cell__label">{t('search.results.columns.mobile.pop_development')}</span>
                    <span className="cell__value">{`${value} %`}</span>
                </div>
            ),
            sorter: {
                compare: (a, b) => {
                    return Number(a.pop_development) - Number(b.pop_development);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(
                namesColumns.vacancy_rate,
                'vacancy_rate',
                sortOrder
            ),
            dataIndex: 'vacancy_rate',
            render: value => {
                return (
                    <div>
                        <span className="cell__value">{`${value} %`}</span>
                    </div>
                )
            },
            sorter: {
                compare: (a, b) => {
                    return Number(a.vacancy_rate) - Number(b.vacancy_rate);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(
                namesColumns.unemployment_rate,
                'unemployment_rate',
                sortOrder
            ),
            dataIndex: 'unemployment_rate',
            render: value => {
                return (
                    <div>
                        <span className="cell__value">{`${value} %`}</span>
                    </div>
                )
            },
            sorter: {
                compare: (a, b) => {
                    return Number(a.unemployment_rate) - Number(b.unemployment_rate);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.gdp, 'gdp', sortOrder),
            dataIndex: 'gdp',
            render: value => numberWithSpaces(value),
            sorter: {
                compare: (a, b) => {
                    return Number(a.gdp) - Number(b.gdp);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.students, 'students', sortOrder),
            dataIndex: 'students',
            render: value => numberWithSpaces(value),
            sorter: {
                compare: (a, b) => {
                    return Number(a.students) - Number(b.students);
                }
            }
        },
        {
            title: (sortOrder) => renderTitle(namesColumns.population, 'population', sortOrder),
            dataIndex: 'population',
            render: value => numberWithSpaces(value),
            sorter: {
                compare: (a, b) => {
                    return Number(a.population) - Number(b.population);
                }
            }
        },
    ];

    const onChangeColumns = (key) => {
        const newSelectedColumns = {
            ...selectedColumns,
            [key]: !selectedColumns[key]
        };
        setSelectedColumns(newSelectedColumns);
    };

    const renderColumn = [
        ...columns.filter(({ dataIndex }) => {
            return selectedColumns[dataIndex];
        }),
        {
            title: () => (
                <div className="table-header__filter">
                    <FilterIcon
                        className="locations-page__results__filter"
                        onClick={() => setVisibleListColumn(true)}
                    />
                    {isVisibleListColumn && (
                        <ListColumns
                            namesColumns={namesColumns}
                            listColumns={getColumnsForSelect()}
                            isVisible={true}
                            setColumns={onChangeColumns}
                            setVisible={setVisibleListColumn}
                        />
                    )}
                </div>
            ),
            dataIndex: 'filter',
            width: 1
        }
    ];

    const resultClassname = classnames(
        'locations-page__results',
        isVisibleState && 'is-visible-state'
    );
    return (
        <>
            <Header />
            <Layout className="locations-page">
                <FiltersLocationPage
                    t={t}
                    initialValues={initialValues}
                    onSubmit={fetchLocations}
                >
                    {(data.length > 0 || statusesLoading.isLoading) && (
                        <Table
                            columns={renderColumn}
                            dataSource={data}
                            rowKey="location_id"
                            className={resultClassname}
                            pagination={{ pageSize }}
                            loading={statusesLoading.isLoading}
                        />
                    )}
                    {data.length === 0 && statusesLoading.isLoaded && (
                        <NoData />
                    )}
                </FiltersLocationPage>
            </Layout>
            <Footer />
        </>
    );
};

export default withTranslation()(Locations);

