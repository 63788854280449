import React from 'react';
import Hint from "../Hint";

import './styles.css';

const FormGroup = ({
    label = null,
    error = null,
    classname = '',
    labelClassname = '',
    errorClassname = '',
    hintClassName = '',
    children = null,
    hint = false
}) => {
    return (
        <div className={['form-group', classname].join(' ')}>
            {label && (<div className={['form-group__label', labelClassname].join(' ')}>
                <span className="form-group__label__text">{label}</span>
                {hint && (
                    <Hint
                        containerClassName="form-group__hint"
                        className={hintClassName}
                        text={hint}
                    />
                )}
            </div>)}
            <div className="form-group__value">
                {children}
            </div>
            {error && (<span className="form-group__error">{error}</span>)}
        </div>
    );
}

export default FormGroup
