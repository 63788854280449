const routes = {
    main: {
        label: "Home",
        value: "/:locale?"
    },
    locations: {
        label: "Locations",
        value: "/:locale?/locations"
    },
    mortgage: {
        label: "Mortgage",
        value: "/:locale?/mortgage"
    },
    about: {
        label: "About Us",
        value: "/:locale?/about"
    },
    appraisal: {
        label: "Appraisal",
        value: "/:locale?/appraisal"
    },
    partners: {
        label: "Partners",
        value: "/:locale?/partners"
    },
    consultation: {
        label: "Consultation",
        value: "/:locale?/consultation"
    },
    contacts: {
        label: "Contacts",
        value: "/:locale?/contacts"
    },
    faq: {
        label: "FAQ",
        value: "/:locale?/faq"
    },
    imprint: {
        label: "Imprint",
        value: "/:locale?/imprint"
    },
    privacy: {
        label: "Data and Privacy",
        value: "/:locale?/privacy"
    },
    team: {
        label: "Team",
        value: "/:locale?/team"
    },
    terms: {
        label: "Terms of use",
        value: "/:locale?/terms"
    },
};

export default routes;
