import React, { useState } from 'react';
import { ReactComponent as DropdownIcon } from '$icons/openplus.svg';
import {withTranslation} from "react-i18next";

const QuestionItem = ({
    title,
    text,
    itemClassName = '',
    rowClassName = '',
    titleClassName = '',
    textClassName = '',
    t
}) => {
    const [isOpen, setOpen] = useState(false);
    const onClick = () => setOpen(!isOpen);
    return (
        <li className={`question-item ${itemClassName}`} onClick={onClick}>
            <div className={`question-item__row ${rowClassName}`}>
                <h2 className={`question-item__title ${titleClassName}`}>{t(title)}</h2>
                <div className="question-item__icon-container">
                    <DropdownIcon
                        className={`question-item__icon ${isOpen && 'is-rotate'}`}
                    />
                </div>
            </div>
            {isOpen && (
                <div className={`question-item__text ${textClassName}`}>
                    {t(text)}
                </div>
            )}
        </li>
    );
};

export default withTranslation()(QuestionItem);
