import React from 'react';
import { ReactComponent as InfoIcon } from "$icons/info.svg";
import { withTranslation } from "react-i18next";

const WarningUsageUnsupportedFilters = ({
    t
}) => {
    const unsupportedFilters = {
        hospitals: t('search.hospitals'),
        stockCompanies: t('search.stock_list_companies'),
        numberRooms: t('search.number_rooms.title'),
        balcony: t('search.balcony'),
        parking: t('search.parking'),
        builtinKitchen: t('search.builtin_kitchen'),
        accessibility: t('search.accessibility')
    };

    const filters = Object.keys(unsupportedFilters).map((key) => {
        if (window.location.search.indexOf(key) !== -1) {
            return unsupportedFilters[key];
        }
        return null;
    }).filter(Boolean).join(', ');

    return filters.length > 0 ? (
        <div className="warning__unsupported-filters">
            <InfoIcon className="warning__unsupported-filters__icon" />
            <span className="warning__unsupported-filters__text">
                {t('search.unsupported_filters', { filters })}
            </span>
        </div>
    ) : <></>;
}

export default withTranslation()(WarningUsageUnsupportedFilters);
