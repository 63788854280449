import React from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import './styles.css';

const Select = ({
    label,
    placeholder,
    data,
    className,
    mode = 'multiSelect',
    ...props
}) => (
    <DropdownTreeSelect
        texts={{ placeholder, label }}
        data={data}
        className={['select', className].join(' ')}
        showPartiallySelected
        mode={mode}
        {...props}
    />
);

export default Select;
