import React from 'react';
import { ErrorMessage } from "formik";
import FormGroup from "$shared/components/FormGroup";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";
import { DoubleInput } from "$shared/components/Inputs";

const DoubleField = ({
    label,
    className,
    min = 0, max = "",
    hint = "Example hint",
    ...props
}) => (
    <FormGroup
        label={label}
        classname={className}
        hint={hint}
        error={(
            <ErrorMessage
                name={props.name}
                children={(errorMessage) => errorMessage}
            />
        )}
    >
        <FormikFastField
            {...props}
            component={DoubleInput}
            min={min}
            max={max}
        />
    </FormGroup>
);

export default DoubleField;
