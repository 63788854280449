import initialValues from "$components/Filters/initialValues";

const normalizeValueFromQueryString = (value, initialValue) => {
    if (value == null || Number.isNaN(Number(value))) {
        return initialValue
    }

    return Number(value);
}

export default (searchString) => {
    if (!searchString) {
        return initialValues;
    }
    const searchParams = new URLSearchParams(searchString);
    return {
        ...initialValues,
        listId: searchParams.get('listId') || '',
        avgYield: {
            min: normalizeValueFromQueryString(searchParams.get('avgYieldMin'), initialValues.avgYield.min),
            max: normalizeValueFromQueryString(searchParams.get('avgYieldMax'), initialValues.avgYield.max),
        },
        populationDev: {
            min: normalizeValueFromQueryString(searchParams.get('populationDevMin'), initialValues.populationDev.min),
            max: normalizeValueFromQueryString(searchParams.get('populationDevMax'), initialValues.populationDev.max),
        },
        population:{
            min: normalizeValueFromQueryString(searchParams.get('populationMin'), initialValues.population.min),
            max: normalizeValueFromQueryString(searchParams.get('populationMax'), initialValues.population.max),
        },
        vacancyRate: {
            min: normalizeValueFromQueryString(searchParams.get('vacancyRateMin'), initialValues.vacancyRate.min),
            max: normalizeValueFromQueryString(searchParams.get('vacancyRateMax'), initialValues.vacancyRate.max),
        },
        gdp: {
            min: normalizeValueFromQueryString(searchParams.get('gdpMin'), initialValues.gdp.min),
            max: normalizeValueFromQueryString(searchParams.get('gdpMax'), initialValues.gdp.max),
        },
        propsPrice: {
            min: normalizeValueFromQueryString(searchParams.get('propsPriceMin'), initialValues.propsPrice.min),
            max: normalizeValueFromQueryString(searchParams.get('propsPriceMax'), initialValues.propsPrice.max),
        },
        propsAge: searchParams.get('propsAge') || initialValues.propsAge,
        propsSize: searchParams.get('propsSize') || initialValues.propsSize,
        populationDevSelect: searchParams.get('populationDevSelect') || initialValues.populationDevSelect,
        vacancyRateSelect: searchParams.get('vacancyRateSelect') || initialValues.vacancyRateSelect,
        gdpSelect: searchParams.get('gdpSelect') || initialValues.gdpSelect,
        areaSize: searchParams.get('areaSize') || initialValues.areaSize,
        university: searchParams.get('university') || initialValues.university,
        numberStudents: {
            min: normalizeValueFromQueryString(
                searchParams.get('numberStudentsMin'), initialValues.numberStudents.min
            ),
            max: normalizeValueFromQueryString(
                searchParams.get('numberStudentsMax'), initialValues.numberStudents.max
            ),
        },
        unemploymentRate: {
            min: normalizeValueFromQueryString(
                searchParams.get('unemploymentRateMin'), initialValues.unemploymentRate.min
            ),
            max: normalizeValueFromQueryString(
                searchParams.get('unemploymentRateMax'), initialValues.unemploymentRate.max
            ),
        },
        hospitals: searchParams.get('hospitals') || initialValues.hospitals,
        stockCompanies: searchParams.get('stockCompanies') || initialValues.stockCompanies,
        numberRooms: {
            min: normalizeValueFromQueryString(
                searchParams.get('numberRoomsMin'), initialValues.numberRooms.min
            ),
            max: normalizeValueFromQueryString(
                searchParams.get('numberRoomsMax'), initialValues.numberRooms.max
            ),
        },
        balcony: searchParams.get('balcony') || initialValues.balcony,
        parking: searchParams.get('parking') || initialValues.parking,
        builtinKitchen: searchParams.get('builtinKitchen') || initialValues.builtinKitchen,
        heatingType: searchParams.get('heatingType') || initialValues.heatingType,
        accessibility: searchParams.get('accessibility') || initialValues.accessibility,
    };
};
