import React, { useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import routes from "$routes";

import { ReactComponent as HamburgerMenuIcon } from '$icons/hamburger.svg';
import { getPath } from "$utils/getPath";
import { MOBILE_SCREEN_900 } from "$shared/constants";
import SimpleSelect from "$shared/components/SimpleSelect";
import MobileMenu from "$shared/components/MobileMenu";
import LanguageSwitcher from "$shared/components/LanguageSwitcher";
import Logo from '$shared/components/Logo';
import { services } from "../Services";

import './styles.css';

const LinksWrapper = styled.div`
    display: inline-block;
    float: right;
    height: 100%;
    :before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
    
    @media (min-width: 901px) {
      display: inline-block;
    }
    
    @media (max-width: 900px) {
      display: none;
    }
`;

const LinkStyled = styled(Link)`
    display: inline-block;
    text-decoration: none;
    color: white;
    vertical-align: middle;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    margin-left: 56px;
    &:hover {
      color: #10ADD0;
    }
`;

const MobileLinkStyled = styled(LinkStyled)`
    display: block;
    margin-bottom: 32px;
    margin-left: 0;
    color: black;
    text-align: left;
`;

let prevScroll = window.scrollY || document.scrollTop || 0;
let curScroll;
let direction = 0;

const Header = ({ t, withBackground = true }) => {
    const [isVisibleMobileMenu, setVisibleMobileMenu] = useState(false);
    const header = useRef(null);

    useEffect(() => {
        onScroll();
        window.addEventListener('scroll', onScroll);
        return () => {
            document.body.style.overflow = '';
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    const onChangeMobileMenu = () => {
        setVisibleMobileMenu(!isVisibleMobileMenu);
        if (!isVisibleMobileMenu) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    };

    const checkScroll = () => {
        curScroll = window.scrollY || document.scrollTop || 0;
        if (curScroll < 0) {
            return;
        }

        if (curScroll > prevScroll) {
            direction = 2;
        } else if (curScroll < prevScroll) {
            direction = 1;
        }

        toggleHeader(direction, curScroll);

        prevScroll = curScroll;
    };

    const toggleHeader = (direction, curScroll) => {
        if (direction === 0) {
            return;
        }

        if (direction === 2 && curScroll > 52) {
            header.current.classList.add('header_is-hide');
        }

        if (direction === 1) {
            header.current.classList.remove('header_is-hide');
        }
    };

    const onScroll = () => {
        const classlist = header.current?.classList;
        const filterSectionHeight = document.querySelector('#filter-section')?.offsetHeight || 0;
        const scrollPercent = (window.scrollY / filterSectionHeight) * 100;
        if (scrollPercent >= 15 && !classlist.contains('header_is-invert')) {
            header.current.classList.add('header_is-invert');
        }

        if (scrollPercent <= 15 && scrollPercent >= 0 && classlist.contains('header_is-invert')) {
            header.current.classList.remove('header_is-invert');
        }

        if (window.innerWidth <= MOBILE_SCREEN_900) {
            checkScroll();
        }
    };

    const normalizeOptions = services
        .map((service) => ({
            ...service,
            children: (
                <Link
                    to={getPath(service.value)}
                    key={service.menulabel}
                    className="header__services-item"
                >
                    {t(service.menulabel)}
                </Link>
            )
        }));
    return (
        <>
            <div className={`header ${withBackground && 'header_is-invert'}`} ref={header}>
                <Logo />
                <LinksWrapper>
                    <SimpleSelect
                        label={t('header.services')}
                        options={normalizeOptions}
                        labelClassname="header__services-label"
                        className="header__services"
                    />
                    <LinkStyled to={getPath(routes.partners.value)}>{t('header.partners')}</LinkStyled>
                    <LinkStyled to={getPath(routes.about.value)}>{t('header.about')}</LinkStyled>
                    <LanguageSwitcher />
                </LinksWrapper>
                <HamburgerMenuIcon
                    className="header__hamburger"
                    onClick={onChangeMobileMenu}
                />
            </div>
            {isVisibleMobileMenu && (
                <MobileMenu
                    onClose={onChangeMobileMenu}
                >
                    <SimpleSelect
                        label={t('header.services')}
                        options={normalizeOptions}
                        labelClassname="header__services-label is-mobile"
                        className="header__services is-mobile"
                        isCloseByOuterClick={false}
                    />
                    <MobileLinkStyled to={getPath(routes.partners.value)}>{t('header.partners')}</MobileLinkStyled>
                    <MobileLinkStyled to={getPath(routes.about.value)}>{t('header.about')}</MobileLinkStyled>
                    <LanguageSwitcher isMobile />
                </MobileMenu>
            )}
        </>
    );
};

export default withRouter(withTranslation()(Header));
