import React from 'react';
import { withTranslation } from "react-i18next";
import Select from "$shared/components/Select";
import FormGroup from "$shared/components/FormGroup";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";

const dependencyFiledName = 'gdp';
const options = [
    {
        id: 'all',
        label: 'search.labels.all',
        value: '',
        dependencyValue: { min: '', max: '' }
    },
    {
        id: 'avg',
        label: 'search.gdp_dev.avg',
        value: 'avg',
        dependencyValue: { min: 42000, max: '' }
    },
    {
        id: 'significantly',
        label: 'search.gdp_dev.significantly',
        value: 'significantly',
        dependencyValue: { min: 70000, max: '' }
    },
    {
        id: 'custom',
        label: 'search.labels.custom',
        value: ''
    }
];

const GDPDev = ({ name, data, onChange, onBlur, className, t }) => (
    <FormGroup
        label={t('search.gdp_dev.title')}
        classname={className}
        hint={t('search.gdp_dev.hint')}
    >
        <FormikFastField
            component={Select}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            mode="radioSelect"
            data={data}
            placeholder={t('search.gdp_dev.placeholder')}
        />
    </FormGroup>
);

export default Object.assign(withTranslation()(GDPDev), {
    options,
    dependencyFiledName
});
