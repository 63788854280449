import React from 'react';
import { withTranslation } from "react-i18next";
import Layout from "$shared/components/Layout";
import Header from "$components/layout/Header";
import Content, { imgPlaces } from "$components/layout/Content";
import Footer from "$components/layout/Footer";
import PhotoImage from '$img/partner.jpg';
// import BenefitItems from "$components/layout/BenefitItems";
//  import Questions from "$components/layout/Questions";
import FormFeedback from "$components/layout/FormFeedback";
// import { questions } from "$pages/common/questions";
// import { benefits } from "$pages/common/benefits"

import './styles.css';

const Partners = ({ t }) => (
    <>
        <Header />
        <Layout className="partners">
{/*            <Content
                containerClass="first-content"
                title="Headline"
            >
                <p>
                    Spend a day with your finger on the rhythmic pulse of Cuban music.
                    We’ll meet at a home studio where you’ll take a front row
                    seat to the modern music scene.
                </p>
                <p>
                    Press play: To begin, learn about the complex history of music in
                    Cuba through personal stories and music documentaries. You’ll even get a peek at
                    some documentaries currently in production.
                </p>
                <p>
                    Skip to the next song: Head to a fascinating record store set inside
                    the home of a local music aficionado.
                    Rummage through stacks of vintage records and purchase a rare vinyl to dance to back home.
                </p>
            </Content>*/}
            <Content
                img={PhotoImage}
                title={t('partner.hero.headline')}
                imgPlace={imgPlaces.RIGHT}
            >
                <p>{t('partner.hero.paragraph1')}</p>
                <p>{t('partner.hero.paragraph2')}</p>
                <p>{t('partner.hero.paragraph3')}</p>
            </Content>
            {/*<BenefitItems benefits={benefits} />*/}
            {/*<Questions questions={questions} />*/}
            <FormFeedback />
        </Layout>
        <Footer />
    </>
);

export default withTranslation()(Partners);
