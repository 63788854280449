import React from 'react';
import { withTranslation } from "react-i18next";
import DropdownLocation from "./components/DropdownLocation";
import RowFilter from "./components/RowFilters";
import AdvancedFilterShow from "./components/AdvancedFilters/AdvancedFilterShow";
import AdvancedFilters from "./components/AdvancedFilters";
import { getMainFilters } from "$components/Filters/filtersSettings";

const Filters = ({ t, values, setFieldValue, isVisible, onShowAdvanced, handleSubmit }) => {

    const filters = getMainFilters({ values, setFieldValue, t });

    return (
        <form className="filters" autoComplete="off" onSubmit={handleSubmit}>
            <div className="filters__row">
                <DropdownLocation
                    name="locations"
                    setFieldValue={setFieldValue}
                    listId={values?.listId}
                />
            </div>
            {filters.map((row) => (
                <RowFilter {...row} key={row.id} />
            ))}
            <AdvancedFilterShow t={t} onShowAdvanced={onShowAdvanced} isVisible={isVisible} />
            {isVisible && (
                <AdvancedFilters
                    values={values}
                    setFieldValue={setFieldValue}
                    t={t}
                    onShowAdvanced={onShowAdvanced}
                    isVisible={isVisible}
                />
            )}
            <button
                className="btn filters__submit is-mobile"
            >
                {t('search.button')}
            </button>
        </form>
    )
};

export default withTranslation()(Filters);
