import DepedentFieldWrapper from "$components/Filters/components/DepedentFieldWrapper";
import PopulationDev from "$components/Filters/components/PopulationDev";
import VacancyRate from "$components/Filters/components/VacancyRate";
import GDPDev from "$components/Filters/components/GDPDev";
import AreaSize from "$components/Filters/components/AreaSize";
import DoubleField from "$components/Filters/components/DoubleField";
import { typesField } from "$shared/components/Inputs";
import PropsAge from "$components/Filters/components/PropsAge";
import PropsSize from "$components/Filters/components/PropsSize";
import CheckboxField from "$components/Filters/components/CheckboxField";

const typeFilters = {
    macro: 'macro_location',
    lookingFor: 'looking_for',
    lookingForAdvanced: 'looking_for_advanced',
    amenities: 'amenities'
};

const getFiltersSettings = (type, formProps) => {
    if (type === typeFilters.macro) {
        return getMacroFilters(formProps);
    }

    if (type === typeFilters.lookingFor) {
        return getLookForFilters(formProps);
    }

    if (type === typeFilters.lookingForAdvanced) {
        return getLookForAdvancedFilters(formProps);
    }

    throw new Error('pass the type filters');
};

const getMacroFilters = ({ values, setFieldValue }) => [{
        name: 'populationDevSelect',
        Component: DepedentFieldWrapper,
        component: PopulationDev,
        options: PopulationDev.options,
        value: values.populationDevSelect,
        dependencyValue: values[PopulationDev.dependencyFiledName],
        dependencyName: PopulationDev.dependencyFiledName,
        setFieldValue
    },
    {
        name: 'vacancyRateSelect',
        Component: DepedentFieldWrapper,
        component: VacancyRate,
        options: VacancyRate.options,
        value: values.vacancyRateSelect,
        dependencyValue: values[VacancyRate.dependencyFiledName],
        dependencyName: VacancyRate.dependencyFiledName,
        setFieldValue
    },
    {
        name: 'gdpSelect',
        Component: DepedentFieldWrapper,
        component: GDPDev,
        options: GDPDev.options,
        value: values.gdpSelect,
        dependencyValue: values[GDPDev.dependencyFiledName],
        dependencyName: GDPDev.dependencyFiledName,
        setFieldValue: setFieldValue
    },
    {
        name: 'areaSize',
        Component: DepedentFieldWrapper,
        component: AreaSize,
        options: AreaSize.options,
        value: values.areaSize,
        dependencyValue: values[AreaSize.dependencyFiledName],
        dependencyName: AreaSize.dependencyFiledName,
        setFieldValue
    }];

const getLookForFilters = ({ values, setFieldValue, t }) => [
    {
        Component: DoubleField,
        name: 'avgYield',
        typeField: typesField.INTEGER,
        label: t('search.avg_yield.title'),
        hint: t('search.avg_yield.hint'),
        placeholder: {
            min: t('search.labels.min'),
            max: t('search.labels.max')
        }
    },
    {
        Component: DoubleField,
        name: 'propsPrice',
        typeField: typesField.INTEGER,
        label: t('search.props_price.title'),
        hint: t('search.props_price.hint'),
        placeholder: {
            min: t('search.labels.min'),
            max: t('search.labels.max')
        }
    },
    {
        name: 'propsAge',
        Component: PropsAge,
        data: PropsAge.options,
        isDesktop: true,
        value: values.propsAge,
        onChange: setFieldValue
    },
    {
        name: 'propsSize',
        Component: PropsSize,
        data: PropsSize.options,
        isDesktop: true,
        value: values.propsSize,
        onChange: setFieldValue
    }
];

const getLookForAdvancedFilters = (props) => getLookForFilters(props)
    .map((filter) => ({
    ...filter,
    isDesktop: false
})).splice(0, 2);

const getMainFilters = ({ values, setFieldValue, t }) => [
    {
        id: 'looking_for',
        label: t('search.rows.looking_for'),
        isDesktopLabel: true,
        filters: getFiltersSettings(typeFilters.lookingFor, { values, setFieldValue, t })
    },
    {
        id: 'macro_location',
        isDesktop: true,
        isDesktopLabel: true,
        label: t('search.rows.macro_location'),
        filters: getFiltersSettings(typeFilters.macro, { values, setFieldValue })
    },
    {
        id: 'university',
        label: null,
        filters: [
            { Component: CheckboxField, name: 'university', label: t('search.university') }
        ]
    },
];

export {
    getMainFilters,
    getFiltersSettings,
    typeFilters
}
