import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import routes from "../routes";
import { withTranslation } from "react-i18next";
import { LANGUAGES } from "$config/appConfig";

import Home from "./Home";
import Mortgage from "./Mortagage";
import About from "./About";
import Appraisal from "./Appraisal";
import Consultation from "./Consultation";
import Contacts from "./Contacts";
import FAQ from "./FAQ";
import Imprint from "./Imprint";
import Partners from "./Partners";
import Privacy from "./Privacy";
import Team from "./Team";
import Terms from "./Terms";
import Locations from "./Locations";
import ScrollIntoView from "$pages/ScrollIntoView";

const RouterContainer = ({ match, i18n }) => {

    useEffect(() => {
        const lang = i18n.language;
        const currentLocale = match.params.locale;

        if (currentLocale) {
            i18n.changeLanguage(currentLocale);
        }

        const isLanguage = LANGUAGES[lang] && lang !== LANGUAGES.en;
        if (!currentLocale && isLanguage) {
            window.location.replace(`/${lang}`);
            return;
        }

        if (lang !== currentLocale && !LANGUAGES[currentLocale]) {
            i18n.changeLanguage(LANGUAGES.en);
        }
    }, []);

    return (
        <ScrollIntoView>
            <Switch>
                <Route exact path={routes.about.value} component={About} />
                <Route exact path={routes.appraisal.value} component={Appraisal} />
                <Route exact path={routes.consultation.value} component={Consultation} />
                <Route exact path={routes.contacts.value} component={Contacts} />
                <Route exact path={routes.imprint.value} component={Imprint} />
                <Route exact path={routes.mortgage.value} component={Mortgage} />
                <Route exact path={routes.partners.value} component={Partners} />
                <Route exact path={routes.privacy.value} component={Privacy} />
                <Route exact path={routes.team.value} component={Team} />
                <Route exact path={routes.terms.value} component={Terms} />
                <Route exact path={routes.faq.value} component={FAQ} />
                <Route exact path={routes.locations.value} component={Locations} />
                <Route exact path={routes.main.value} component={Home} />
                <Route render={() => <h1>Страница не найдена</h1>} />
            </Switch>
        </ScrollIntoView>
    );
};

export default withTranslation()(RouterContainer);
