import React from 'react';

import './styles.css';

const Layout = ({ children, className = '' }) => (
    <div className={`layout ${className}`}>
        {children}
    </div>
);

export default Layout;
