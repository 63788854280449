const request = (idsLocations) => new Promise((resolve, reject) => {
    const url = new URL('https://api.centsnhomes.com/api/v1/resources/locations_list');
    fetch(url, {
       method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "locations": idsLocations
        })
    }).then((response) => {
        resolve(response.json());
    }).catch((error) => {
        reject(error);
    });
});

export default request;
