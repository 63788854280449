import React from 'react';
import { withFormik } from "formik";
import { withTranslation } from "react-i18next";

const EmailForm = ({
    handleSubmit,
    setFieldValue,
    t
}) => {
    const onChange = (event) => {
        const { name, value } = event.target;
        setFieldValue(name, value);
    };
    return (
        <form onSubmit={handleSubmit} className="get-in-touch-form">
            <input
                name="email"
                placeholder={t('home.touch.placeholder')}
                onChange={onChange}
                className="get-in-touch-form__input"
            />
            <button type="submit" className="btn get-in-touch-form__button">
                {t('home.touch.button')}
            </button>
        </form>
    );
};

export default withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({ email: '' }),
    handleSubmit: ({ email }, { props, setSubmitting }) => {
        setSubmitting(false);
        props.onSubmit(email);
    }
})(withTranslation()(EmailForm));
