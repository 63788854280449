import React, { useEffect, useState } from 'react';
import { getDefaultValue } from "./helpers";
import { withTranslation } from "react-i18next";

const DepedentFieldWrapper = ({
    value,
    name,
    dependencyValue,
    dependencyName,
    setFieldValue,
    t,
    options = [],
    component,
    ...props
}) => {
    const [data, setData] = useState(getDefaultValue(t, options));

    useEffect(() => {
        if (value) {
            updateData(value);
        }

        let isDiffValues = false;
        const isDependencyValue = dependencyValue
            && (dependencyValue?.min !== ''
            || dependencyValue?.max !== '');

        const [valueFromOptions] = options.filter((option) => option.value === value);
        if (valueFromOptions && (
            valueFromOptions?.dependencyValue?.min !== dependencyValue?.min
            || valueFromOptions?.dependencyValue?.max !== dependencyValue?.max
        )) {
            isDiffValues = true;
        }

        if ((!value && isDependencyValue) || isDiffValues) {
            setFieldValue(name, '');
            updateData('custom');
        }
    }, [value, dependencyValue]);

    useEffect(() => {
        updateLang();
    }, [t]);

    const onChange = (selectedOption) => {
        setFieldValue(name, selectedOption.value);

        if (dependencyName && selectedOption.dependencyValue) {
            setFieldValue(dependencyName, selectedOption.dependencyValue);
        }

        updateData(selectedOption.id);
    };

    const updateData = (id) => {
        setData(data.map((option) => ({
            ...option,
            checked: id === option.id
        })));
    };

    const updateLang = () => {
        setData(data.map((option) => ({
            ...option,
            label: t(option.untranslatedLabel),
        })));
    };

    const onBlur = () => {};

    const Component = component;

    return (
        <Component
            data={data}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
        />
    );
};

export default withTranslation()(DepedentFieldWrapper);
