import React, { useEffect, useState } from 'react';
import { ErrorMessage, withFormik } from "formik";
import { withTranslation } from "react-i18next";
import { pageclip } from "$services/index";
import FormikFastField from "$shared/components/FormikInputs/FormikFields";
import FormGroup from "$shared/components/FormGroup";

import './styles.css';
import Button from "$shared/components/Button";

const validate = (values, props) => {
    const errors = {};
    if (!values.name) {
        errors.name = props.t('feedback_form.error_name');
    }
    if (!values.email) {
        errors.email = props.t('feedback_form.error_email');
    }
    return errors;
}

let isMount = false;

const FormFeedback = ({
   handleSubmit,
   t,
    values,
}) => {
    const [statusesLoading, setStatusesLoading] = useState({
        isLoading: false,
        isError: false,
    });
    const [isSuccess, setSuccess] = useState(false);
    useEffect(() => {
        isMount = true;
        return () => {
            isMount = false;
        }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        handleSubmit();
        const errors = validate(values, { t });

        if (Object.keys(errors).length > 0) {
            return;
        }

        setStatusesLoading({
            isLoading: true,
            isError: false
        });

        pageclip(values).then(() => {
            if (!isMount) {
                return;
            }
            setStatusesLoading({
                isLoading: false,
                isError: false
            });
            setSuccess(true);
            setTimeout(() => {
                if (isMount) {
                    setSuccess(false);
                }
            }, 5000);
        }).catch((error) => {
            console.error(error);
            if (!isMount) {
                return;
            }
            setStatusesLoading({
                isLoading: false,
                isError: true
            });
            setTimeout(() => {
                if (isMount) {
                    setStatusesLoading({
                        isLoading: false,
                        isError: false
                    });
                }
            }, 5000);
        });
    };

    return (
        <div className="feedback-form-container">
            <h1 className="feedback-form__title">{t('feedback_form.title')}</h1>
            <form
                onSubmit={onSubmit}
                className="feedback-form"
            >
                <FormGroup
                    label={t('feedback_form.name')}
                    classname="feedback-form__item"
                    error={
                        <ErrorMessage
                            name="name"
                            children={(errorMessage) => errorMessage}
                        />
                    }
                >
                    <FormikFastField
                        name="name"
                        component={Input}
                        placeholder={t('feedback_form.placeholders.name')}
                    />
                </FormGroup>
                <FormGroup
                    label={t('feedback_form.email')}
                    classname="feedback-form__item"
                    error={
                        <ErrorMessage
                            name="email"
                            children={(errorMessage) => errorMessage}
                        />
                    }
                >
                    <FormikFastField
                        name="email"
                        component={Input}
                        placeholder={t('feedback_form.placeholders.email')}
                    />
                </FormGroup>
                <FormGroup
                    label={t('feedback_form.description')}
                    classname="feedback-form__item"
                >
                    <FormikFastField
                        name="description"
                        placeholder={t('feedback_form.placeholders.description')}
                        component={TextArea}
                    />
                </FormGroup>
                <div className="feedback-form__footer">
                    <Button
                        type="submit"
                        classname="feedback-form__btn"
                        loading={statusesLoading.isLoading}
                    >
                        {t('feedback_form.button')}
                    </Button>
                    {isSuccess && (
                        <div className="get-in-touch__status is-success">{t('home.touch.success')}</div>
                    )}
                    {statusesLoading.isError && (
                        <div className="get-in-touch__status is-error">{t('feedback_form.error_send')}</div>
                    )}
                </div>
            </form>
        </div>
    );
};

const Input = ({ ...props }) => (
    <input {...props} className={props.error && 'is-error'} />
);
const TextArea = ({ ...props }) => (<textarea {...props} />);

export default withTranslation()(withFormik({
    enableReinitialize: true,
    validate,
    mapPropsToValues: () => ({ name: '', email: '', description: '' }),
    handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(false);
    }
})(FormFeedback));
