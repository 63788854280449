import React, { useCallback, useState } from 'react';
import { withTranslation } from "react-i18next";
import { pageclip } from "$services/index";
import EmailForm from "./EmailForm";

import './styles.css';

const EmailTouch = ({ t }) => {
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    const [isEmptyEmail, setEmptyEmail] = useState(false);
    const handleErrorByType = {
        error: setError,
        empty_email: setEmptyEmail
    };

    const onSubmit = useCallback((email) => {
        if (!email) {
            handleError('empty_email');
            return;
        }

        pageclip({
            name: '',
            email,
            description: `Hello! Someone signed up for the updates with mail: ${email}`
        }).then(() => {
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        }).catch((error) => {
            console.error(error);
            handleError();
        });
    }, []);

    const handleError = (typeError = 'error') => {
        const handler = handleErrorByType[typeError];
        handler(true);
        setTimeout(() => {
            handler(false);
        }, 10000);
    };

    return (
        <>
            <span className="get-in-touch__label">{t('home.touch.label')}</span>
            <EmailForm onSubmit={onSubmit} />
            {isSuccess && (
                <div className="get-in-touch__status is-success">{t('home.touch.success')}</div>
            )}
            {isError && (
                <div className="get-in-touch__status is-error">{t('home.touch.error')}</div>
            )}
            {isEmptyEmail && (
                <div className="get-in-touch__status is-error">{t('home.touch.empty_email')}</div>
            )}
        </>
    )
};

export default withTranslation()(EmailTouch);
